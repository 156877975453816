import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "提示文字" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx._placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._placeholder) = $event)),
          size: "default",
          placeholder: "请设置日期提示"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "日期格式" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          modelValue: _ctx._value.format,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx._value.format) = $event)),
          size: "default"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              value: "yyyy",
              label: "年"
            }),
            _createVNode(_component_el_option, {
              value: "yyyy-MM",
              label: "年-月"
            }),
            _createVNode(_component_el_option, {
              value: "yyyy-MM-dd",
              label: "年-月-日"
            }),
            _createVNode(_component_el_option, {
              value: "yyyy-MM-dd HH:mm",
              label: "年-月-日 时:分"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}